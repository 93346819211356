import axios, { AxiosPromise } from 'axios';

export default class CompanyService {
  public static getCompanySettings(): AxiosPromise {
    return axios.get('http://localhost/api/employee/company');
  }

  public static updateCompanySettings(data: object): AxiosPromise {
    return axios.put('http://localhost/api/employee/company', data);
  }

  public static getCompanyUsers(): AxiosPromise {
    return axios.get('http://localhost/api/employee/company/users');
  }

  public static inviteUser(data: object): AxiosPromise {
    return axios.post('http://localhost/api/employee/company/employee-invitations', data);
  }
}
