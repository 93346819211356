import axios, { AxiosPromise } from 'axios';

export default class AuthService {
  public static redirectToLoginPage(): void {
    window.location.href = 'http://localhost/login';
  }

  public static getCsrfCookie(): AxiosPromise {
    return axios.get('http://localhost/sanctum/csrf-cookie');
  }

  public static getAuthenticatedUser(): AxiosPromise {
    return axios.get('http://localhost/api/authenticated-user');
  }

  public static logout(): AxiosPromise {
    return axios.post('http://localhost/api/logout');
  }
}
