import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vSelect from 'vue-select';
import axios, { AxiosResponse } from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import loadingStore from '@/store/modules/loadingStore';
import AuthService from '@/services/authService';

// eslint-disable-next-line import/no-unresolved
import './assets/index.css';

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;

// When data fetching has started, set the loading state to true
axios.interceptors.request.use((config) => {
  loadingStore.setLoading(true);

  return config;
});

// When data fetching is done, set the loading state to false
axios.interceptors.response.use((response): AxiosResponse => {
  loadingStore.setLoading(false);

  return response;
});

// Check for 401 or 419 response status codes
axios.interceptors.response.use((response): AxiosResponse => response, (error) => {
  loadingStore.setLoading(false);

  if (error.response.status === 401 || error.response.status === 419) {
    AuthService.redirectToLoginPage();
  }

  return Promise.reject(error);
});

Vue.component('v-select', vSelect);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
