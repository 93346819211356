













import { Component, Vue } from 'vue-property-decorator';
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue';
import TheSidebar from '@/components/TheSidebar.vue';

@Component({
  components: { TheSidebar, TheLoadingIndicator },
})
export default class App extends Vue {
}
