






































































import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import { Challenge } from '@/types/challenge';
import ChallengeService from '@/services/challengeService';
import ChallengeCard from '@/components/ChallengeCard.vue';

@Component({
  components: { ChallengeCard },
})
export default class ChallengeOverviewPage extends Vue {
  public publishedChallenges: Challenge[] | null = null;

  public draftChallenges: Challenge[] | null = null;

  public created(): void {
    this.getCompanyChallenges();
  }

  public getCompanyChallenges(): void {
    ChallengeService.getCompanyChallenges().then((response: AxiosResponse<any>) => {
      this.publishedChallenges = response.data.data;
      this.draftChallenges = response.data.data;
    });
  }
}
