import {
  getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { User } from '@/types/user';
import store from '@/store';

@Module({
  namespaced: true,
  name: 'authStore',
  store,
  dynamic: true,
})
class AuthStore extends VuexModule {
  public authenticatedUser: User | null = null;

  public authStateReady = false;

  get getAuthenticatedUser() {
    return this.authenticatedUser;
  }

  get getIsAuthenticated() {
    return !!this.authenticatedUser;
  }

  get getAuthStateReady() {
    return this.authStateReady;
  }

  @Mutation
  setAuthStateReady() {
    this.authStateReady = true;
  }

  @Mutation
  setAuthenticatedUser(user: User) {
    this.authenticatedUser = user;
  }

  @Mutation
  clearAuthenticatedUser() {
    this.authenticatedUser = null;
  }
}

export default getModule(AuthStore);
