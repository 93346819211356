import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import authStore from '@/store/modules/authStore';
import AuthService from '@/services/authService';
import DashboardPage from '@/views/DashboardPage.vue';
import ChallengeOverviewPage from '@/views/ChallengeOverviewPage.vue';
import CompanySettingsPage from '@/views/CompanySettingsPage.vue';
import CompanyUsersPage from '@/views/CompanyUsersPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    meta: { requiresAuthentication: true },
  },
  {
    path: '/challenges',
    name: 'challenge-overview-page',
    component: ChallengeOverviewPage,
    meta: { requiresAuthentication: true },
  },
  {
    path: '/company/settings',
    name: 'company-settings',
    component: CompanySettingsPage,
    meta: { requiresAuthentication: true },
  },
  {
    path: '/company/users',
    name: 'company-users',
    component: CompanyUsersPage,
    meta: { requiresAuthentication: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = authStore.getIsAuthenticated;
  const authStateReady = authStore.getAuthStateReady;
  const requiresAuthentication = to.matched.some((match) => match.meta.requiresAuthentication);

  if (!isAuthenticated && !authStateReady) {
    try {
      const response = await AuthService.getAuthenticatedUser();

      if (response.status === 200) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        authStore.setAuthenticatedUser(response.data.data);
        isAuthenticated = true;
      }
    } catch (error) {
      console.log(error.data);
    } finally {
      authStore.setAuthStateReady();
    }
  }

  if (requiresAuthentication && !isAuthenticated) {
    AuthService.redirectToLoginPage();

    return;
  }

  if (!requiresAuthentication && isAuthenticated) {
    next({ name: 'dashboard' });

    return;
  }

  next();
});

export default router;
