



















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const loadingStore = namespace('loadingStore');

@Component
export default class ThePageLoader extends Vue {
  @loadingStore.State
  public loading!: boolean;
}
