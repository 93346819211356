























































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AxiosResponse } from 'axios';
import { User } from '@/types/user';
import AuthService from '@/services/authService';
import { Company } from '@/types/company';
import CompanyService from '@/services/companyService';
import SidebarItem from '@/components/SidebarItem.vue';

const authStore = namespace('authStore');
@Component({
  components: { SidebarItem },
})
export default class ThePageLoader extends Vue {
  @authStore.State
  public authenticatedUser!: User;

  public currentCompany: Company | null = null;

  public created(): void {
    CompanyService.getCompanySettings().then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        this.currentCompany = response.data.data;
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public logout(): void {
    AuthService.logout().then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        AuthService.redirectToLoginPage();
      }
    });
  }
}
