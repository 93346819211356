





























import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import CompanyService from '@/services/companyService';
import { User } from '@/types/user';

@Component({})
export default class CompanySettings extends Vue {
  public users: User[] | null = null;

  public created(): void {
    this.getCompanyUsers();
  }

  public getCompanyUsers(): void {
    CompanyService.getCompanyUsers().then((response: AxiosResponse<any>) => {
      this.users = response.data.data;
    });
  }

  public inviteUser(): void {
    CompanyService.inviteUser({
      first_name: 'Different',
      last_name: 'Employee',
      email: 'different-employee@company.com',
    }).then((response: AxiosResponse<any>) => {
      alert(response.data.message);
    }).catch(() => {
      alert('Invalid data, please try again.');
    });
  }
}
