

























































































import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import { Company } from '@/types/company';
import CompanyService from '@/services/companyService';
import BlackbearTextInput from '@/components/validation/BlackbearTextInput.vue';
import { ValidationErrorResponse } from '@/types/validationErrorResponse';

@Component({
  components: { BlackbearTextInput },
})
export default class CompanySettings extends Vue {
  public company: Company | null = null;

  // Holds the data that is the same as the required fields of the API request
  public companySettingsForm = {
    name: null,
    website_url: null,
    about: null,
    primary_color: null,
    text_color: null,
  }

  // Holds the validation errors for the company settings form
  public companySettingsFormErrors: ValidationErrorResponse | null = null;

  public created(): void {
    this.getCompanySettings();
  }

  public getCompanySettings(): void {
    CompanyService.getCompanySettings().then((response: AxiosResponse<any>) => {
      this.companySettingsForm = response.data.data;
    });
  }

  public updateCompanySettings(): void {
    CompanyService.updateCompanySettings(this.companySettingsForm).then((response: AxiosResponse<any>) => {
      this.company = response.data.data;
      this.companySettingsForm = response.data.data;
    }).catch((error) => {
      if (error.response.status === 422) {
        this.companySettingsFormErrors = error.response.data;
      }
    });
  }
}
