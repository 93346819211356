import {
  getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import store from '@/store';

@Module({
  namespaced: true,
  name: 'loadingStore',
  store,
  dynamic: true,
})
class LoadingStore extends VuexModule {
  public loading = false;

  get getLoading() {
    return this.loading;
  }

  @Mutation
  setLoading(val: boolean) {
    this.loading = val;
  }
}

export default getModule(LoadingStore);
