











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Challenge } from '@/types/challenge';

@Component
export default class ChallengeCard extends Vue {
  @Prop({ default: () => null }) readonly challenge!: Challenge | null;

  // eslint-disable-next-line class-methods-use-this
  public getRandomUnsplashHeader(): string {
    // eslint-disable-next-line no-undef
    const options = [
      'medical', 'office', 'industrial', 'finance',
      'marketing', 'construction', 'code', 'hacking',
      'gym', 'food', 'airplane',
    ];

    return `https://source.unsplash.com/featured/?${options[Math.floor(Math.random() * options.length)]}`;
  }
}
