










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SidebarItem extends Vue {
  @Prop({ default: () => null }) readonly route!: Object;

  @Prop({ default: () => '' }) readonly text!: string;
}
