





































import Vue from 'vue';
import {
  Component, Emit, Prop, Ref, Watch,
} from 'vue-property-decorator';
import { ValidationErrorResponse } from '@/types/validationErrorResponse';

@Component
export default class BlackbearTextInput extends Vue {
  @Prop({ default: () => null }) readonly name!: string | null;

  @Prop({ default: () => null }) readonly value!: string | null;

  @Prop({ default: () => null }) readonly validationErrors!: ValidationErrorResponse | null;

  @Prop({ default: () => '' }) readonly autocompleteCode!: string;

  @Prop({ default: () => null }) readonly label!: string | null;

  @Prop({ default: () => null }) readonly placeholder!: string | null;

  @Prop({ default: () => false }) readonly required!: boolean;

  @Ref('text-input') readonly textInput!: HTMLInputElement;

  @Watch('validationErrors', { deep: true })
  public onProvidedError(): void {
    if (this.textInput && this.name && this.validationErrors) {
      if (this.validationErrors.errors[this.name]) {
        this.textInput.focus();
      }
    }
  }

  public clearError(): void {
    if (this.validationErrors && this.name) {
      delete this.validationErrors.errors[this.name];
    }
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  public input(val: string): string {
    return val;
  }
}
